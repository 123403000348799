<template>
  <div class="unauthorized">
    <v-container>
      <v-layout row wrap>
        <v-flex xs12>
          <v-card height="400" color="transparent" flat class="text-center mx-auto">
            <div class="font-weight-bold text-h1 my-8" style="letter-spacing: 0.5rem; opacity: 0.25">401</div>
            <div class="title">Not Authorized</div>
            <div class="paragraph-text mt-4">
              <div class="mt-4">You are not authorized for selected action.</div>
              <div>Try going back to home page and repeating your action. Or, contact helpdesk for support.</div>
              <div class="mt-4">
                <v-btn @click="logout" color="primary">RETURN HOME</v-btn>
              </div>
            </div>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'Unauthorized',
  methods: {
    logout() {
      this.$keycloak.logoutFn();
    },
  },
};
</script>

<style lang="scss">
.unauthorized {
  padding-top: 5rem;
}
</style>
